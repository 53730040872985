import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Button, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import Notification from "../../../Notification";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { retrieveError } from "../../../utilities/constants";
import Swipper from "./swipper";
import PresentationPage from "./presentation-page";

const RecoverPw = () => {
  const { t, i18n } = useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [valueEmail, setValueEmail] = useState("");
  let history = useNavigate();
  let token = window.location.search.split("?tokenId=")[1];

  const handleValueEmail = (e) => {
    setValueEmail(e.target.value);
  };

  const mutation = useMutation({
    mutationFn: (email) => {
      return axios.post(
        "https://api.seeks.biz/users/password_reset/",
        email
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Email sent successfully!"),
        type: "success",
      });
    },
  });

  const handleResetPassword = () => {
    mutation.mutate({
      email: valueEmail,
    });
  };

  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5 order-md-1 order-2">
              <Swipper />
            </Col>
            <Col
              md="6"
              className="bg-white pt-5 pt-5 pb-lg-0 pb-5 order-md-2 order-1"
            >
              <div className="sign-in-from">
                <h1 className="mb-0" style={{ textAlign: "center" }}>
                  {t("Reset Password")}
                </h1>
                <br />
                <br />
                <p style={{ textAlign: "center" }}>
                  <span style={{ textAlign: "justify" }}>
                    {t(
                      "Enter your email address and we will send you a mail to reset your password."
                    )}{" "}
                    <br />
                  </span>
                </p>

                <TextField
                  label={t("Email address")}
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={valueEmail}
                  onChange={handleValueEmail}
                />
                <br />

                <br />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                  onClick={handleResetPassword}
                >
                  {t("Reset Password")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="presentation-section">
        <PresentationPage />
      </section>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default RecoverPw;
