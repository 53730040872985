import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Notification from "../../../Notification";
import { retrieveError } from "../../../utilities/constants";
import Swipper from "./swipper";
import PresentationPage from "./presentation-page";

const GetTokenActivation = () => {
  const { t, i18n } = useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [valueEmail, setValueEmail] = useState("");

  const handleValueEmail = (e) => {
    setValueEmail(e.target.value);
  };

  const fetchToken = (valueEmail) => {
    return axios.get(
      `https://api.seeks.biz/users/send-activation-token?email=${valueEmail}`
    );
  };

  const handleFetchToken = async () => {
    try {
      const result = await fetchToken(valueEmail);
      setNotify({
        isOpen: true,
        message: t("Email sent successfully!"),
        type: "success",
      });
    } catch (error) {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5 order-md-1 order-2">
              <Swipper />
            </Col>
            <Col
              md="6"
              className="bg-white pt-5 pt-5 pb-lg-0 pb-5 order-md-2 order-1"
            >
              <div className="sign-in-from">
                <h1 className="mb-0" style={{ textAlign: "center" }}>
                  {t("Get activation token")}
                </h1>
                <br />
                <br />
                <p style={{ textAlign: "center" }}>
                  <span style={{ textAlign: "justify" }}>
                    {t(
                      "Enter your email address and we will send you a token to activate your account."
                    )}
                    <br />
                  </span>
                </p>
                {/* {error ? <></> : <></>} */}
                <TextField
                  label={t("Email")}
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={valueEmail}
                  onChange={handleValueEmail}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                  onClick={handleFetchToken}
                >
                  {t("Reset Password")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="presentation-section">
        <PresentationPage />
      </section>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default GetTokenActivation;
