import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player/lazy";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enStrings from "react-timeago/lib/language-strings/en";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import { HeaderContext } from "../../../components/partials/dashboard/HeaderStyle/headerContext";

import {
  getInitialsBis,
  retrieveError,
  stringToColorBis,
} from "../../../utilities/constants";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import Notification from "../../../Notification";
import axiosInstance from "../../../useApi";
import FormAnswerPost from "./form-answer-posts";

const ListPosts = () => {
  const { t, i18n } = useTranslation();

  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);

  const { headerValue } = useContext(HeaderContext);

  const [posts, setPosts] = useState();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const fetchPosts = async () => {
    try {
      const { data } = await axiosInstance.post(
        "/socialmedia/requests/",
        headerValue !== "" && JSON.parse(headerValue).only_words
          ? {
              only_words: JSON.parse(headerValue).only_words.map(
                (item) => item.id
              ),
            }
          : headerValue !== "" && JSON.parse(headerValue).exclude_words
          ? {
              exclude_words: JSON.parse(headerValue).exclude_words.map(
                (item) => item.id
              ),
            }
          : undefined
      );
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const handleRefetchPosts = () => {
    mutationFetchPost.mutate();
  };

  useEffect(() => {
    mutationFetchPost.mutate();
  }, [headerValue]);

  const mutationFetchPost = useMutation({
    mutationFn: (post) => {
      return axiosInstance.post(
        "https://api.seeks.biz/socialmedia/requests/",
        headerValue !== "" && JSON.parse(headerValue).only_words
          ? {
              only_words: JSON.parse(headerValue).only_words.map(
                (item) => item.id
              ),
              exclude_my_own_answers: true,
            }
          : headerValue !== "" && JSON.parse(headerValue).exclude_words
          ? {
              exclude_words: JSON.parse(headerValue).exclude_words.map(
                (item) => item.id
              ),
              exclude_my_own_answers: true,
            }
          : { exclude_my_own_answers: true }
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setPosts(data.data);
    },
  });

  const formatImages = (images) => {
    let formattedImages = [];
    images.forEach((element) => {
      formattedImages.push({
        original: element.image,
        thumbnail: element.image,
        originalHeight: "360",
      });
    });
    return formattedImages;
  };

  return (
    <span id="list-posts-home">
      {!posts || posts.length <= 0 ? (
        <div className="col-sm-12 text-center">
          <CircularProgress />
        </div>
      ) : posts.results && posts.results.length <= 0 ? (
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          <Trans>No post found</Trans>
        </div>
      ) : (
        posts?.results
          ?.filter((elt) => !elt.is_not_in_first_response)
          .map((post, index) => (
            <div>
              <div className="card card-block card-stretch card-height">
                <div className="card-body">
                  <div className="user-post-data">
                    <div className="d-flex justify-content-between">
                      <div className="me-3">
                        <Tooltip
                          title={
                            <span>
                              {t("Company name")} {": "}{" "}
                              {post.author.company_name} <br />
                              {t("Job title")} {": "} {post.author.position}{" "}
                              <br />
                              {t("Seniority in position")} {": "}{" "}
                              {format(
                                post.author.job_seniority
                                  ? new Date(post.author.job_seniority)
                                  : new Date(),
                                "dd-MM-yyyy"
                              )}
                              <br />
                            </span>
                          }
                        >
                          <Avatar
                            src={post.author.avatar}
                            sx={{
                              bgcolor:
                                post.author.avatar === null ||
                                post.author.avatar === ""
                                  ? stringToColorBis(
                                      post.author.first_name,
                                      post.author.last_name
                                    )
                                  : "transparent",
                            }}
                          >
                            {post.author &&
                            post.author.avatar &&
                            post.author.avatar !== null
                              ? post.author.avatar
                              : getInitialsBis(
                                  post.author.first_name,
                                  post.author.last_name
                                )}
                          </Avatar>
                        </Tooltip>
                      </div>
                      <div className="w-100">
                        <div className="d-flex  justify-content-between">
                          <div>
                            <h5 className="mb-0 d-inline-block">
                              {post.author?.first_name} {post.author?.last_name}
                            </h5>
                            <span className="mb-0 ps-1 d-inline-block">
                              <Trans>Added a new Post in sector</Trans>
                              &nbsp;{post.business_sector}
                            </span>
                            <p className="mb-0 text-primary">
                              <TimeAgo
                                date={post.created_at}
                                formatter={
                                  localStorage.getItem("i18next") === "fr"
                                    ? formatterBis
                                    : formatter
                                }
                              />
                            </p>
                          </div>
                          <div className="card-post-toolbar">
                            <div style={{ textAlign: "end" }}>
                              <LocationOnIcon />{" "}
                              {post.location_type === "local" ? (
                                post.location +
                                " (+" +
                                post.location_radius +
                                " km)"
                              ) : post.location_type === "régional" ? (
                                post.location
                              ) : (
                                <Trans>National</Trans>
                              )}
                            </div>
                            <div style={{ textAlign: "end" }}>
                              <EventIcon />{" "}
                              {format(new Date(post.valid_until), "dd-MM-yyyy")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p style={{ whiteSpace: "pre-line" }}>{post.content}</p>
                  </div>
                  <div className="user-post mt-3">
                    {post.images && post.images.length > 0 ? (
                      <ImageGallery
                        loading={"lazy"}
                        items={formatImages(post.images)}
                        showPlayButton={false}
                        thumbnailPosition="right"
                        useBrowserFullscreen={false}
                      />
                    ) : null}
                  </div>
                  <div className="user-post mt-3">
                    <Grid container spacing={2}>
                      {post.attached_files &&
                      post.attached_files.length > 0 &&
                      post.attached_files.find(
                        (elt) => !elt.attached?.endsWith(".pdf")
                      )
                        ? post.attached_files
                            .filter((elt) => !elt.attached?.endsWith(".pdf"))
                            .map((video) => (
                              <Grid item xl={4} md={6} xs={12}>
                                <ReactPlayer
                                  url={video.attached}
                                  controls={true}
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                            ))
                        : null}
                    </Grid>
                  </div>
                  <div className="user-post mt-3">
                    <Grid container spacing={2}>
                      {post.attached_files &&
                      post.attached_files.length > 0 &&
                      post.attached_files.find((elt) =>
                        elt.attached?.endsWith(".pdf")
                      )
                        ? post.attached_files
                            .filter((elt) => elt.attached?.endsWith(".pdf"))
                            .map((file) => (
                              <Grid item key={file.id}>
                                <a
                                  href={file.attached}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Box
                                    elevation={3}
                                    sx={{
                                      position: "relative",
                                    }}
                                  >
                                    <PictureAsPdfIcon
                                      color="error"
                                      sx={{ fontSize: 64 }}
                                    />
                                    <Tooltip
                                      title={
                                        file.attached.split(
                                          "/media/posts/attached/"
                                        )[1]
                                      }
                                    >
                                      <Typography
                                        sx={{
                                          maxWidth: 100,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {
                                          file.attached.split(
                                            "/media/posts/attached/"
                                          )[1]
                                        }
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </a>
                              </Grid>
                            ))
                        : null}
                    </Grid>
                  </div>
                  <FormAnswerPost
                    idPost={post.id}
                    answersCount={post.answers_count}
                    handleRefetchPosts={handleRefetchPosts}
                  />
                </div>
              </div>
            </div>
          ))
      )}

      {posts?.results?.filter((elt) => elt.is_not_in_first_response).length >
      0 ? (
        <>
          <br />
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "-16px" }}
          >
            <hr style={{ width: "100%", marginRight: "24px" }} />
            <Trans>
              Here are some more posts related to your sector and not only the
              words you chose.
            </Trans>
            <hr style={{ width: "100%", marginLeft: "24px" }} />
          </Box>
          <br />
        </>
      ) : null}
      {posts?.results
        ?.filter((elt) => elt.is_not_in_first_response)
        .map((post, index) => (
          <div>
            <div className="card card-block card-stretch card-height">
              <div className="card-body">
                <div className="user-post-data">
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Tooltip
                        title={
                          <span>
                            {t("Company name")} {": "}{" "}
                            {post.author.company_name} <br />
                            {t("Job title")} {": "} {post.author.position}{" "}
                            <br />
                            {t("Seniority in position")} {": "}{" "}
                            {format(
                              post.author.job_seniority
                                ? new Date(post.author.job_seniority)
                                : new Date(),
                              "dd-MM-yyyy"
                            )}{" "}
                            <br />
                          </span>
                        }
                      >
                        <Avatar
                          src={post.author.avatar}
                          sx={{
                            bgcolor:
                              post.author.avatar === null ||
                              post.author.avatar === ""
                                ? stringToColorBis(
                                    post.author.first_name,
                                    post.author.last_name
                                  )
                                : "transparent",
                          }}
                        >
                          {post.author &&
                          post.author.avatar &&
                          post.author.avatar !== null
                            ? post.author.avatar
                            : getInitialsBis(
                                post.author.first_name,
                                post.author.last_name
                              )}
                        </Avatar>
                      </Tooltip>
                    </div>
                    <div className="w-100">
                      <div className="d-flex  justify-content-between">
                        <div>
                          <h5 className="mb-0 d-inline-block">
                            {post.author?.first_name} {post.author?.last_name}
                          </h5>
                          <span className="mb-0 ps-1 d-inline-block">
                            <Trans>Added a new Post in sector</Trans>
                            &nbsp;{post.business_sector}
                          </span>
                          <p className="mb-0 text-primary">
                            <TimeAgo
                              date={post.created_at}
                              formatter={
                                localStorage.getItem("i18next") === "fr"
                                  ? formatterBis
                                  : formatter
                              }
                            />
                          </p>
                        </div>
                        <div className="card-post-toolbar">
                          <div style={{ textAlign: "end" }}>
                            <LocationOnIcon />{" "}
                            {post.location_type === "local" ? (
                              post.location +
                              " (+" +
                              post.location_radius +
                              " km)"
                            ) : post.location_type === "régional" ? (
                              post.location
                            ) : (
                              <Trans>National</Trans>
                            )}
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <EventIcon />{" "}
                            {format(new Date(post.valid_until), "dd-MM-yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p style={{ whiteSpace: "pre-line" }}>{post.content}</p>
                </div>
                <div className="user-post mt-3">
                  {post.images && post.images.length > 0 ? (
                    <ImageGallery
                      loading={"lazy"}
                      items={formatImages(post.images)}
                      showPlayButton={false}
                      thumbnailPosition="right"
                      useBrowserFullscreen={false}
                    />
                  ) : null}
                </div>
                <div className="user-post mt-3">
                  <Grid container spacing={2}>
                    {post.attached_files &&
                    post.attached_files.length > 0 &&
                    post.attached_files.find(
                      (elt) => !elt.attached?.endsWith(".pdf")
                    )
                      ? post.attached_files
                          .filter((elt) => !elt.attached?.endsWith(".pdf"))
                          .map((video) => (
                            <Grid item xl={4} md={6} xs={12}>
                              <ReactPlayer
                                url={video.attached}
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </div>
                <div className="user-post mt-3">
                  <Grid container spacing={2}>
                    {post.attached_files &&
                    post.attached_files.length > 0 &&
                    post.attached_files.find((elt) =>
                      elt.attached?.endsWith(".pdf")
                    )
                      ? post.attached_files
                          .filter((elt) => elt.attached?.endsWith(".pdf"))
                          .map((file) => (
                            <Grid item key={file.id}>
                              <a
                                href={file.attached}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box
                                  elevation={3}
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    color="error"
                                    sx={{ fontSize: 64 }}
                                  />
                                  <Tooltip
                                    title={
                                      file.attached.split(
                                        "/media/posts/attached/"
                                      )[1]
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: 100,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        file.attached.split(
                                          "/media/posts/attached/"
                                        )[1]
                                      }
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </a>
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </div>
                <FormAnswerPost
                  idPost={post.id}
                  answersCount={post.answers_count}
                  handleRefetchPosts={handleRefetchPosts}
                />
              </div>
            </div>
          </div>
        ))}

      <Notification notify={notify} setNotify={setNotify} />
    </span>
  );
};

export default ListPosts;
