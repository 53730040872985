import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

// img
import error404 from "../../../assets/images/error/400.png";

const Error404 = () => {
  let history = useNavigate();
  return (
    <>
      <Container className="p-0">
        <Row className="no-gutters height-self-center">
          <Col sm="12" className="text-center align-self-center">
            <div className="iq-error position-relative mt-5">
              <Image src={error404} className="img-fluid iq-error-img" alt="" />
              <h2 className="mb-0 text-center">
                <Trans>Oops! This Page is Not Found.</Trans>
              </h2>
              <p className="text-center">
                <Trans>The requested page does not exist.</Trans>
              </p>
              <Button
                variant="primary"
                className="mt-3"
                onClick={() => history("/")}
              >
                <i className="ri-home-4-line me-1"></i>
                <Trans>Back to Home</Trans>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Error404;
