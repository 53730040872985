import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PresentationPage from "./presentation-page";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Notification from "../../../Notification";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { listThemes, retrieveError } from "../../../utilities/constants";
import { useLocation } from "react-router-dom";

import Swipper from "./swipper";

const SignUp = () => {
  const navigate = useNavigate();

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const { t, i18n } = useTranslation();

  const schema = yup.object().shape({
    step1: yup.object().shape({
      firstName: yup.string().required(t("First name is required")),
      lastName: yup.string().required(t("Last name is required")),
      email: yup
        .string()
        .email(t("Invalid email"))
        .required(t("Email is required")),
      password: yup.string().required(t("Password is required")),
    }),
    step2: yup.object().shape({
      companyName: yup.string().required(t("Company name is required")),
      siret: yup.string().required(t("SIRET is required")),
      company_address: yup.string().required(t("Company address is required")),
      jobTitle: yup.string().required(t("Job title is required")),
      seniority: yup.date().required(t("Seniority is required")),
    }),
  });

  const steps = [
    t("Your infos"),
    t("Your company's infos"),
    t("Your list of words"),
  ];

  const [isVisibleMessageAdress, setIsVisibleMessageAdress] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const fetchCities = async (searchText) => {
    try {
      const { data } = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${searchText}&limit=10`
      );
      return data.features.map((address) => {
        return {
          label: address.properties.label,
          x: address.geometry.coordinates[0],
          y: address.geometry.coordinates[1],
          region:
            address.properties.context &&
            address.properties.context.split(", ").length === 1
              ? address.properties.context.split(", ")[1]
              : address.properties.context.split(", ")[2],
          city: address.properties.city,
          postal_code: address.properties.postcode
            ? address.properties.postcode
            : address.properties.citycode,
        };
      });
    } catch (error) {
      console.error("Error fetching cities:", error);
      return [];
    }
  };

  const { data: cities, isLoading } = useQuery(
    ["cities", searchText],
    () => fetchCities(searchText),
    {
      enabled: !!searchText,
    }
  );

  const mutationNbPosts = useMutation({
    mutationFn: (user) => {
      return axios.post(
        "https://api.seeks.biz/socialmedia/howmanyposts/",
        user
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      const newWord = {
        word,
        theme,
        nbOccu: data.data?.number_of_posts ? data.data?.number_of_posts : 0,
      };

      setWordList([...wordList, newWord]);
      setWord("");
      setTheme("");
      setOpen(false);
      // setNotify({
      //   isOpen: true,
      //   message: t("Word added successfully!"),
      //   type: "success",
      // });
    },
  });

  const [valueAddress, setValueAddress] = useState("");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const [word, setWord] = useState("");
  const [theme, setTheme] = useState("");
  const [wordList, setWordList] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= 960 ? true : false
  );

  const [isLargeMobileView, setIsLargeMobileView] = useState(
    window.innerWidth <= 960 && window.innerWidth > 768 ? true : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 960);
      setIsLargeMobileView(window.innerWidth <= 960 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mutationCheckPwd = useMutation({
    mutationFn: (password) => {
      return axios.post(
        "https://api.seeks.biz/users/check-password/",
        password
      );
    },
    onError: (error) => {
      setPasswordError(error.response.data);
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setPasswordError(null);
      if (!emailError && !passwordError) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    },
  });

  const mutationCheckEmail = useMutation({
    mutationFn: (email) => {
      return axios.post("https://api.seeks.biz/users/check-email/", email);
    },
    onError: (error) => {
      setEmailError(error.response.data);
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setEmailError(null);
      mutationCheckPwd.mutate({
        password: getValues("step1.password"),
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeTheme = async (e) => {
    setTheme(e.target.value);
  };

  const handleNext = async (e) => {
    if (activeStep === 0) {
      let step1Fields = false;
      step1Fields = await trigger([
        "step1.firstName",
        "step1.lastName",
        "step1.email",
        "step1.password",
      ]);

      if (step1Fields) {
        mutationCheckEmail.mutate({
          email: getValues("step1.email"),
        });
      }
    } else if (activeStep === 1) {
      let step2Fields = false;
      step2Fields = await trigger([
        "step2.companyName",
        "step2.siret",
        "step2.company_address",
        "step2.jobTitle",
        "step2.seniority",
      ]);
      if (step2Fields) {
        if (valueAddress?.x && valueAddress?.y) {
          setIsVisibleMessageAdress(false);
          setTimeout(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }, 50);
        } else {
          setIsVisibleMessageAdress(true);
        }
      }
    }
  };

  const handleValueAddress = async (value) => {
    setValueAddress(value);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddWord = async () => {
    if (word && theme) {
      mutationNbPosts.mutate({
        words: [
          {
            word: word,
            theme: theme,
          },
        ],
        latitude: valueAddress.y,
        longitude: valueAddress.x,
        region: valueAddress.region,
      });
    }
  };

  const handleDeleteWord = (id) => {
    let updatedWL = wordList.filter((elt, index) => index !== id);
    setWordList(updatedWL);
  };

  const mutation = useMutation({
    mutationFn: (user) => {
      return axios.post("https://api.seeks.biz/users/register/", user);
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Account created successfully!"),
        type: "success",
      });
      navigate("/auth/mail-sent");
    },
  });

  const handleFormSubmit = (data, event) => {
    if (wordList.length === 3) {
      const searchParams = new URLSearchParams(window.location.search);
      const affiliate = searchParams.get("affiliate");

      let requestData = {
        password: data.step1.password,
        email: data.step1.email,
        first_name: data.step1.firstName,
        last_name: data.step1.lastName,
        position: data.step2.jobTitle,
        job_seniority: format(data.step2.seniority, "yyyy-MM-dd"),
        siret: data.step2.siret,
        company_name: data.step2.companyName,
        longitude: valueAddress.x,
        latitude: valueAddress.y,
        region: valueAddress.region,
        city: valueAddress.city,
        postal_code: valueAddress.postal_code,
        address: valueAddress.label,
        words: wordList.map(({ nbOccu, ...rest }) => rest),
        language:
          navigator.language.includes("fr") ||
          navigator.language.includes("FR") ||
          navigator.language === undefined
            ? "fr"
            : "en",
        ...(affiliate && { affiliate }),
      };

      if (affiliate) {
        requestData.affiliate = affiliate;
      }

      mutation.mutate(requestData);
    }
  };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <section className="sign-in-page">
          <div id="container-inside">
            <div id="circle-small"></div>
            <div id="circle-medium"></div>
            <div id="circle-large"></div>
            <div id="circle-xlarge"></div>
            <div id="circle-xxlarge"></div>
          </div>
          <Container className="p-0">
            <Row className="no-gutters">
              {!isMobileView ? (
                <Col
                  md="6"
                  className="text-center pt-5 order-md-1 order-2"
                  style={{ maxWidth: "50%" }}
                >
                  <Swipper />
                </Col>
              ) : (
                <></>
              )}
              <Col
                md={!isMobileView ? "6" : "12"}
                className="bg-white pt-5 pt-5 pb-lg-0 pb-5 order-md-2 order-1"
                style={{
                  overflowY: "auto",
                  maxHeight: "100vh",
                }}
              >
                <div className="sign-in-from">
                  <h1 className="mb-0">
                    <Trans>Sign Up</Trans>
                  </h1>
                  <p>
                    <Trans>
                      Please complete this 3 steps form to sign up. Every field
                      is required.
                    </Trans>
                  </p>
                  <form
                    className="mt-4"
                    onSubmit={handleSubmit(handleFormSubmit)}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {activeStep === steps.length ? (
                        <>
                          <Typography>
                            {t("All steps completed - you're finished")}
                          </Typography>
                          <Button variant="contained" color="primary">
                            <Trans>Reset</Trans>
                          </Button>
                        </>
                      ) : (
                        <>
                          {activeStep === 0 ? (
                            <>
                              <hr />
                              <Controller
                                name="step1.firstName"
                                key="firstname"
                                control={control}
                                render={({ field, onChange }) => (
                                  <TextField
                                    label={t("First name")}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                  />
                                )}
                              />
                              {errors.step1?.firstName && (
                                <span className="text-danger">
                                  {errors.step1.firstName.message}
                                </span>
                              )}
                              <Controller
                                name="step1.lastName"
                                control={control}
                                key="lastname"
                                render={({ field }) => (
                                  <TextField
                                    label={t("Last name")}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                  />
                                )}
                              />
                              {errors.step1?.lastName && (
                                <span className="text-danger">
                                  {errors.step1.lastName.message}
                                </span>
                              )}
                              <Controller
                                name="step1.email"
                                control={control}
                                key="email"
                                render={({ field }) => (
                                  <TextField
                                    label={t("Email")}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                  />
                                )}
                              />
                              {errors.step1?.email && (
                                <span className="text-danger">
                                  {errors.step1.email.message}
                                </span>
                              )}
                              <Controller
                                name="step1.password"
                                control={control}
                                key="password"
                                render={({ field }) => (
                                  <TextField
                                    label={t("Password")}
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          style={{ marginRight: "8px" }}
                                        >
                                          <Tooltip
                                            title={
                                              <span>
                                                {t("Please select a password:")}{" "}
                                                <br />-{" "}
                                                {t("8 characters minimum")}{" "}
                                                <br />-{" "}
                                                {t(
                                                  'At least one capital letter, one lowercase letter, one digit, one special character among !@#$%^&*(),.?":{}|<>+'
                                                )}{" "}
                                                <br />- {t("Not too common")}
                                              </span>
                                            }
                                          >
                                            <HelpOutlineIcon color="primary" />
                                          </Tooltip>
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                            size="large"
                                          >
                                            {showPassword ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                              {errors.step1?.password && (
                                <span className="text-danger">
                                  {errors.step1.password.message}
                                </span>
                              )}
                            </>
                          ) : activeStep === 1 ? (
                            <>
                              <hr />
                              <Controller
                                name="step2.companyName"
                                key="companyName"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    label={t("Company name")}
                                    key="companyName"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                  />
                                )}
                              />
                              {errors.step2?.companyName && (
                                <span className="text-danger">
                                  {errors.step2.companyName.message}
                                </span>
                              )}
                              <Controller
                                name="step2.siret"
                                control={control}
                                key="siret"
                                render={({ field }) => (
                                  <TextField
                                    label={t("SIRET (14 characters)")}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                  />
                                )}
                              />
                              {errors.step2?.siret && (
                                <span className="text-danger">
                                  {errors.step2.siret.message}
                                </span>
                              )}
                              <Controller
                                name="step2.company_address"
                                control={control}
                                key="company_address"
                                render={({ field }) => (
                                  <Autocomplete
                                    id="city-search"
                                    options={cities || []}
                                    // loading={isLoading}
                                    freeSolo
                                    onInputChange={(event, newInputValue) => {
                                      setSearchText(newInputValue);
                                    }}
                                    value={valueAddress}
                                    onChange={(event, value) => {
                                      handleValueAddress(value);
                                    }}
                                    filterOptions={(options, { inputValue }) =>
                                      options
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={t("Company address")}
                                        variant="outlined"
                                        {...field}
                                        margin="normal"
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {/* {isLoading ? (
                                          <span>Loading...</span>
                                        ) : null} */}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                              {errors.step2?.company_address && (
                                <span className="text-danger">
                                  {errors.step2.company_address.message}
                                </span>
                              )}
                              {isVisibleMessageAdress && (
                                <span className="text-danger">
                                  <Trans>
                                    Please search for an address and pick one
                                  </Trans>
                                </span>
                              )}
                              <Controller
                                name="step2.jobTitle"
                                control={control}
                                key="jobTitle"
                                render={({ field }) => (
                                  <TextField
                                    label={t("Job title")}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                  />
                                )}
                              />
                              {errors.step2?.jobTitle && (
                                <span className="text-danger">
                                  {errors.step2.jobTitle.message}
                                </span>
                              )}
                              <Controller
                                name="step2.seniority"
                                control={control}
                                key="seniority"
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={
                                      localStorage.getItem("i18nextLng") ===
                                      "en"
                                        ? enGB
                                        : fr
                                    }
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <DesktopDatePicker
                                        {...field}
                                        format="dd/MM/yyyy"
                                        sx={{
                                          width: "100%",
                                        }}
                                        margin="normal"
                                        disableFuture="true"
                                        label={t("Seniority in position")}
                                        error={errors.step2?.seniority}
                                        helperText={
                                          errors.step2?.seniority &&
                                          errors.step2.seniority.message
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                )}
                              />
                              {errors.step2?.seniority && (
                                <span className="text-danger">
                                  {errors.step2.seniority.message}
                                </span>
                              )}
                            </>
                          ) : activeStep === 2 ? (
                            <span>
                              <div>
                                <hr />
                                <span className="text-center">
                                  <p></p>

                                  <p>
                                    <Tooltip
                                      title={
                                        t(
                                          "Now is the most import part of the parm, the choice of your words (exactly 3 for now) which will define the posts you'll be able to see in SEEKS."
                                        ) +
                                        " \n" +
                                        t(
                                          "Pick couples of word/themes related to your business activity to be able to provide your goods/services to everyone needing it !"
                                        )
                                      }
                                    >
                                      <HelpOutlineIcon color="primary" />
                                    </Tooltip>
                                  </p>
                                </span>
                                <div>
                                  <Button
                                    onClick={handleOpen}
                                    variant="contained"
                                    color="primary"
                                    disabled={wordList.length > 2}
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      display: "block",
                                    }}
                                  >
                                    <Trans>Add a word/business sector</Trans>
                                  </Button>
                                </div>
                              </div>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ width: "10%" }}>
                                        <Trans>Word</Trans>
                                      </TableCell>
                                      <TableCell sx={{ width: "80%" }}>
                                        <Trans>Business sector</Trans>
                                      </TableCell>
                                      <TableCell
                                        sx={{ width: "5%" }}
                                        align="right"
                                      >
                                        <Trans>Posts found</Trans>
                                      </TableCell>
                                      <TableCell
                                        sx={{ width: "5%" }}
                                      ></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {wordList.map((item, index) => (
                                      <TableRow key={index}>
                                        <TableCell sx={{ width: "10%" }}>
                                          {item.word}
                                        </TableCell>
                                        <TableCell sx={{ width: "80%" }}>
                                          {item.theme}
                                        </TableCell>
                                        <TableCell
                                          sx={{ width: "5%" }}
                                          align="right"
                                        >
                                          {typeof item.nbOccu === "number" ? (
                                            <Chip
                                              label={item.nbOccu}
                                              color={
                                                item.nbOccu > 4
                                                  ? "primary"
                                                  : "error"
                                              }
                                            />
                                          ) : null}
                                        </TableCell>
                                        <TableCell sx={{ width: "105%" }}>
                                          <IconButton
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              handleDeleteWord(index);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <br />
                            </span>
                          ) : null}
                          <hr />
                          <div>
                            {/* {activeStep === 0 ? null : ( */}
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              variant="outlined"
                              color="primary"
                            >
                              <Trans>Back</Trans>
                            </Button>
                            {/* )} */}
                            {activeStep === steps.length - 1 &&
                            wordList.length !== 3 ? (
                              <></>
                            ) : activeStep === steps.length - 1 &&
                              wordList.length === 3 ? (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ float: "right" }}
                                type="submit"
                                disabled={wordList.length < 3}
                              >
                                <Trans>Submit</Trans>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ float: "right" }}
                                onClick={handleNext}
                              >
                                <Trans>Next</Trans>
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </Box>
                  </form>
                  <div className="sign-info">
                    <span className="dark-color d-inline-block line-height-2">
                      <Trans>Already Have Account ? </Trans>{" "}
                      <Link to="/auth/sign-in">
                        <Trans>Log In</Trans>
                      </Link>
                    </span>
                  </div>
                </div>
                {isMobileView ? (
                  <div className="container-scroll">
                    <div
                      className="center-text-arrow"
                      style={{ marginLeft: "-14px" }}
                    >
                      <Trans>Learn more</Trans>
                    </div>
                    <div>
                      <svg class="arrows">
                        <path class="a1" d="M0 0 L30 32 L60 0"></path>
                        <path class="a2" d="M0 20 L30 52 L60 20"></path>
                        <path class="a3" d="M0 40 L30 72 L60 40"></path>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </section>
        <section className="presentation-section">
          <PresentationPage />
        </section>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Trans>Add a word/business sector</Trans>
          </DialogTitle>
          <DialogContent>
            <TextField
              label={t("Word")}
              variant="outlined"
              fullWidth
              value={word}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (!inputValue.includes(" ")) {
                  setWord(inputValue);
                }
              }}
              style={{ marginTop: "8px" }}
            />
            <FormControl fullWidth style={{ marginTop: "16px" }}>
              <InputLabel>
                <Trans>Business sector</Trans>
              </InputLabel>
              <Select
                fullWidth
                value={theme}
                label="Business sector"
                onChange={handleChangeTheme}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ position: "absolute", right: "36px" }}
                  >
                    <Tooltip
                      title={t(
                        "These words are linked to sectors, so that someone looking for bass fishing equipment 🎣, does not see his request sent to a luthier 🎸."
                      )}
                    >
                      <HelpOutlineIcon color="primary" />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                {listThemes.map((sector) => (
                  <MenuItem value={sector}>{sector}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              onClick={handleAddWord}
              variant="contained"
              className="btn btn-primary me-2"
              style={{
                marginLeft: "auto",
                marginTop: "12px",
                display: "block",
              }}
              disabled={word.length < 2}
            >
              <Trans>Add</Trans>
            </Button>
          </DialogContent>
        </Dialog>
        <Notification notify={notify} setNotify={setNotify} />
      </ThemeProvider>
    </>
  );
};

export default SignUp;
